// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  ModalConfirmState,
  OpenModalAction,
  SetStatusAction
} from "./modal-confirm.types";

// ** Initial State
const initialState: ModalConfirmState = {
  visible: false,
  message: "",
  onConfirm: () => {},
  acceptButtonText: "Si, Eliminar",
  cancelButtonText: "No, regresar",
  icon: null,
  status: "idle"
};

export const modalConfirm = createSlice({
  name: "modalConfirm",
  initialState,
  reducers: {
    openModal: (state, action: OpenModalAction) => {
      state.visible = true;
      state.message = action.payload.message;
      state.onConfirm = action.payload.onConfirm;
      state.acceptButtonText = action.payload.acceptButtonText;
      state.cancelButtonText = action.payload.cancelButtonText;
      state.icon = action.payload.icon;
    },
    closeModal: (state) => {
      state.visible = false;
      state.message = "";
      state.onConfirm = () => {};
      state.acceptButtonText = "Si, Eliminar";
      state.cancelButtonText = "No, regresar";
      state.icon = null;
    },
    setStatus: (state, action: SetStatusAction) => {
      state.status = action.payload;
    }
  }
});

export const { openModal, closeModal, setStatus } = modalConfirm.actions;

export const modalConfirmReducer = modalConfirm.reducer;
