import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../api/config";
import { AccType } from "../../components/common/filters";
import { AppDispatch, RootState } from "../store";
import { ToastSuccess } from "../../assets/images/icons";
import SuccessToast from "../../components/common/toast/SuccessToast";
import ErrorToast from "../../components/common/toast/ErrorToast";
import { ApiReturn, ParamsWithId } from "./payments.types";

export const getPayments = createAsyncThunk<
  ApiReturn,
  AccType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payments/get", async (params: AccType, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const finalParams = { ...params, product };
  const response = await api.get(`api/payments`, { params: finalParams });
  return {
    data: response.data,
    params
  };
});

export const getPaymentsSuggestions = createAsyncThunk<
  ApiReturn,
  AccType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payments/getSuggestions", async (params: AccType, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const finalParams = { ...params, product };
  const response = await api.get(`api/payments/all/list`, {
    params: finalParams
  });
  return {
    data: response.data,
    params
  };
});

export const sendPaymentByEmail = createAsyncThunk<
  ApiReturn,
  ParamsWithId,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payment/sendByEmail", async (params: ParamsWithId) => {
  const { id, params: paramsToSend } = params;
  try {
    const response = await api.post(`api/payment_receipts/send_mail/${id}`, paramsToSend);
    toast(<SuccessToast message={response.data.message} submessage={null} />, {
      icon: <ToastSuccess />,
      hideProgressBar: true,
      className: "toast-success",
      autoClose: 5000
    });
    return {
      data: response.data,
      params
    };
  } catch (err) {
    ErrorToast({ message: "Error al enviar el correo" });
    return {
      data: null,
      params
    };
  }
});

export const deletePayment = createAsyncThunk<
  ApiReturn,
  AccType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("payments/delete", async (params: AccType) => {
  const { id } = params;
  const response = await api.delete(`api/payments/${id}`);
  return {
    data: response.data,
    params
  };
});
