// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { Cert } from '../assets/images/icons'

export const modalTypeEdit = createSlice({
  name: 'modals_custom',
  initialState: {
    toggle: false,
    message: "",
    action: null,
    cancelAction: null,
    icon: null,
    confirmText: "Si, Guardar",
    cancelText: null,
    notes: null,
    secondaryButton: null,
    loading: false,
    message_align: "left",
    notes_location: "bottom"
  },
  reducers: {
    showModalCustom: (state, action) => {
      state.toggle = true
      state.message = action.payload.message
      state.action = action.payload.action
      state.icon = action.payload.icon
      state.confirmText = action.payload.confirmText
      state.cancelText = action.payload.cancelText
      if (action.payload.notes) {
        state.notes = action.payload.notes
      }

      if (action.payload.message_align) {
        state.message_align = action.payload.message_align
      }

      if (action.payload.notes_location) {
        state.notes_location = action.payload.notes_location
      }

      if (action.payload.secondaryButton) {
        state.secondaryButton = action.payload.secondaryButton
      }
    },
    hideModalCustom: (state) => {
      state.toggle = false
      state.message = ""
      state.action = null
      state.notes = ""
      state.notes_location = "bottom"
      state.message_align = "left"
      state.secondaryButton = null
      state.loading = false
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const { showModalCustom, hideModalCustom, setLoading } = modalTypeEdit.actions

export default modalTypeEdit.reducer
