// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"
import { showModalError } from "../../../../redux/modalTypeError"
import {
  getPolicyByProvider,
  getPolicyByProviderAP
} from "../../Providers/store"

const urlBase = "api/providers"

/**
 * Retrive providers catalog
 */
export const getClausesProviders = createAsyncThunk(
  "providers/get",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    params.product = productSelected.value
    const response = await api.get(`${urlBase}`, {
      params: {
        ...params,
        product: productSelected.value,
        business_line: businessLineSelected?.id
      }
    })
    return {
      params,
      data: response.data
    }
  }
)

/**
 * Retrieve clauses catalos
 */
export const getPolicyTypes = createAsyncThunk(
  "clauses/get",
  async (params = {}, { getState }) => {
    const state = getState()

    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines

    params.product = productSelected.value
    params.business_line = businessLineSelected?.id

    const response = await api.get("api/policy_types", { params })
    return {
      params,
      data: response.data
    }
  }
)

/**
 * Retrieve clauses by id
 */
export const getPolicyTypeById = createAsyncThunk(
  "clauses/getById",
  async (id) => {
    const response = await api.get(`api/policy_types/${id}`)
    return {
      data: response.data
    }
  }
)

export const updateMainPolicy = createAsyncThunk(
  "policy/updateMain",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`api/main_policy/${id}`, data)

    if (response.data.status === "success") {
      const url = `/catalogs/clauses`
      dispatch(showModalSuccess({ message: response.data.message, url }))
    }

    return {
      data: response.data
    }
  }
)

export const getProviderById = createAsyncThunk(
  "providers/getById",
  async (id) => {
    const response = await api.get(`${urlBase}/${id}`)
    return {
      data: response.data
    }
  }
)

/**
 * Save clauses, text
 */
export const editPolicyType = createAsyncThunk(
  "clauses/save",
  async ({ id, policy = null, data, url = `goBack` }, { dispatch }) => {
    const response = await api
      .put(`api/policy_types/${id}`, data)
      .catch((err) => {
        dispatch(
          showModalError({ message: JSON.stringify(err.response.data.errors) })
        )
      })
    // console.debug("response", response)
    if (policy) {
      if (policy.plan_id) {
        dispatch(
          getPolicyByProviderAP({
            provider: policy.provider_id,
            currency: policy.currency_id,
            plan: policy.plan_id
          })
        )
      } else {
        dispatch(
          getPolicyByProvider({
            provider: policy.provider_id,
            currency: policy.currency_id
          })
        )
      }

      dispatch(getPolicyTypeById(id))
    }
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      data: response.data
    }
  }
)

export const editProvider = createAsyncThunk(
  "providers/save",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`${urlBase}/${id}`, data)
    const url = `goBack`
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      data: response.data
    }
  }
)

export const providerSlice = createSlice({
  name: "providers",
  initialState: {
    data: null,
    options: [],
    policies: [],
    policies_options: [],
    loading: false,
    submitLoading: false
  },
  reducers: {
    resetProviders: (state) => {
      state.data = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClausesProviders.fulfilled, (state, action) => {
        // console.debug("getClausesProviders", action.payload)
        if (action.payload?.params?.format === "options") {
          state.options = action.payload.data
        } else {
          state.data = action.payload.data.data
        }
      })
      .addCase(getProviderById.fulfilled, (state, action) => {
        state.data = action.payload.data.data
      })
      .addCase(editProvider.fulfilled, (state, action) => {
        state.data = action.payload.data.data
      })
      .addCase(getPolicyTypes.pending, (state) => {
        state.loading = true
      })
      .addCase(getPolicyTypes.rejected, (state) => {
        state.loading = false
      })
      .addCase(getPolicyTypes.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload?.params?.format === "options") {
          state.policies_options = action.payload.data
        } else {
          state.policies = action.payload.data.data
        }
      })
      .addCase(getPolicyTypeById.fulfilled, (state, action) => {
        state.policies = action.payload.data.data
      })
      .addCase(updateMainPolicy.fulfilled, (state) => {
        state.submitLoading = false
      })
      .addCase(updateMainPolicy.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(updateMainPolicy.rejected, (state) => {
        state.submitLoading = false
      })
  }
})

export const { resetProviders } = providerSlice.actions

export default providerSlice.reducer
