import { Fragment } from "react"
import { toast } from "react-toastify"
import { Danger } from "../../../assets/images/icons"
import themeConfig from "../../../configs/themeConfig"

export const DangerToast = ({ message }) => (
  <Fragment>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text-danger'>
        {message}
      </span>
    </div>
  </Fragment>
)

const ErrorToast = ({ message }) => {
  toast(<DangerToast message={message} />, {
    icon: <Danger />,
    hideProgressBar: true,
    className: "toast-danger",
    autoClose: themeConfig.layout.toast_time
  })
}

export default ErrorToast
