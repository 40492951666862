// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import { AuthState, User } from "./auth.types";
import { getMe, refreshToken, revokeToken } from "./async-thunks";

const config = useJwt.jwtConfig;

const initialUser = (): User | null => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? (JSON.parse(item) as User) : null;
};

const initialAccessToken = () => {
  const item = window.localStorage.getItem("token");
  //** Parse stored json or if none return initialValue
  return item ? item : null;
};

const initialRefreshAccessToken = () => {
  const item = window.localStorage.getItem("refreshToken");
  //** Parse stored json or if none return initialValue
  return item ? item : null;
};

const initialLimit = (): number | null => {
  const item = window.localStorage.getItem("limit");
  //** Parse stored json or if none return initialValue
  return item ? parseInt(item, 10) : null;
};

const initialState: AuthState = {
  userData: initialUser(),
  accessToken: initialAccessToken(),
  refreshToken: initialRefreshAccessToken(),
  limit: initialLimit()
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken
      );
      localStorage.setItem("limit", action.payload.limit);
    },
    handleLogout: (state) => {
      state.userData = null;
      state.accessToken = null;
      state.refreshToken = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
      localStorage.removeItem("limit");
      // ** Clear localStorage
      localStorage.clear();
      // ** Reload page
      window.location.reload();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(revokeToken.fulfilled, (state) => {
      state.userData = null;
      state.accessToken = null;
      state.refreshToken = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      const user = action.payload.data.data;
      state.userData = user;
      localStorage.setItem("userData", JSON.stringify(state.userData));
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      state.accessToken = data.token;
      state.refreshToken = data.refresh_token;
      state.limit = data.limit;
      localStorage.setItem(config.storageTokenKeyName, data.token);
      localStorage.setItem(config.storageTokenKeyName, data.refresh_token);
      localStorage.setItem("limit", data.limit);
    });
  }
});

export const { handleLogin, handleLogout } = authSlice.actions;

export const authReducer = authSlice.reducer;
