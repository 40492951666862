import { createSlice } from "@reduxjs/toolkit";
import {
  attachPaymentMethod,
  deleteBankCard,
  getBankCards,
  getSetupIntent,
  getSetupIntentById,
  setBankCardAsDefault
} from "./async-thunks";
import { BankCardsState } from "./bank-cards.types";

const initialState: BankCardsState = {
  setupIntent: null,
  card: null,
  cards: [],
  loading: false,
  error: null,
  deleted: false,
  deleteError: null,
  settedAsDefault: false,
  setAsDefaultError: null
};

export const bankCardsSlice = createSlice({
  name: "bank-cards",
  initialState,
  reducers: {
    clearSetupIntent: (state) => {
      state.setupIntent = null;
      state.loading = false;
      state.error = null;
    },
    clearCard: (state) => {
      state.card = null;
      state.loading = false;
      state.error = null;
    },
    clearDeleted: (state) => {
      state.deleted = false;
      state.deleteError = null;
    },
    clearSetAsDefault: (state) => {
      state.settedAsDefault = false;
      state.setAsDefaultError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSetupIntent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSetupIntent.fulfilled, (state, action) => {
      state.setupIntent = action.payload;
      state.loading = false;
    });
    builder.addCase(getSetupIntent.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(getSetupIntentById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSetupIntentById.fulfilled, (state, action) => {
      state.setupIntent = action.payload;
      state.loading = false;
    });
    builder.addCase(getSetupIntentById.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(attachPaymentMethod.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(attachPaymentMethod.fulfilled, (state, action) => {
      state.loading = false;
      console.log("action.payload", action.payload);
      state.card = action.payload.bank_card;
    });
    builder.addCase(attachPaymentMethod.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(getBankCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBankCards.fulfilled, (state, action) => {
      state.loading = false;
      console.log("action.payload.bank_cards", action.payload);
      state.cards = action.payload.data.bank_cards;
    });
    builder.addCase(getBankCards.rejected, (state, action) => {
      state.error = action.error.message as string;
      state.loading = false;
    });
    builder.addCase(deleteBankCard.pending, (state) => {
      state.loading = true;
      state.deleted = false;
    });
    builder.addCase(deleteBankCard.fulfilled, (state) => {
      state.loading = false;
      state.deleted = true;
    });
    builder.addCase(deleteBankCard.rejected, (state, action) => {
      state.deleteError = action.error.message as string;
      state.loading = false;
      state.deleted = false;
    });
    builder.addCase(setBankCardAsDefault.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setBankCardAsDefault.fulfilled, (state) => {
      state.loading = false;
      state.settedAsDefault = true;
    });
    builder.addCase(setBankCardAsDefault.rejected, (state, action) => {
      state.setAsDefaultError = action.error.message as string;
      state.loading = false;
    });
  }
});

export const { clearSetupIntent, clearCard, clearDeleted, clearSetAsDefault } =
  bankCardsSlice.actions;
export const bankCardsReducer = bankCardsSlice.reducer;
