import { createSlice } from "@reduxjs/toolkit";
import {
  deletePayment,
  getPayments,
  getPaymentsSuggestions,
  sendPaymentByEmail
} from "./async-thunks";
import { PaymentsState, SetCurrentActionProps } from "./payments.types";

const initialState: PaymentsState = {
  data: {
    data: [],
    meta: null
  },
  params: {},
  autocompleteValues: [],
  loading: false,
  message: "",
  response: null,
  currentAction: null,
  showPayment: null
};

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearPayments: (state) => {
      state.data = { data: [], meta: null };
      state.params = {};
      state.autocompleteValues = [];
      state.loading = false;
      state.message = "";
      state.response = null;
      state.currentAction = null;
      state.showPayment = null;
    },
    paymentSelected: (state, action) => {
      state.showPayment = action.payload;
      // TODO: Remove this localStorage setItem, is risky and not recommended
      localStorage.setItem(
        "show_payment",
        JSON.stringify(action.payload.payment)
      );
    },
    getPaymentSelected: (state) => {
      const showPaymentItem = localStorage.getItem("show_payment");
      if (showPaymentItem !== null) {
        const showPayment = JSON.parse(showPaymentItem);
        state.showPayment = showPayment;
      }
    },
    setCurrentAction: (state, action: SetCurrentActionProps) => {
      state.currentAction = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.params = action.payload.params;
    });
    builder.addCase(getPayments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPayments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPaymentsSuggestions.fulfilled, (state, action) => {
      state.autocompleteValues = action.payload.data;
    });
    builder.addCase(deletePayment.fulfilled, (state, action) => {
      state.message = action.payload.data.message;
    });
    builder.addCase(sendPaymentByEmail.fulfilled, (state, action) => {
      state.response = action.payload.data;
    });
  }
});

export const {
  clearPayments,
  paymentSelected,
  getPaymentSelected,
  setCurrentAction
} = paymentSlice.actions;
export const paymentsReducer = paymentSlice.reducer;
