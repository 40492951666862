// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

const urlBase = "api/coverages"

export const getCoverages = createAsyncThunk("coverages/getAll", async (params, {getState}) => {
  const state = getState()

  const { productSelected } = state.products
  const { businessLineSelected } = state.businessLines

  params.product = productSelected?.value
  params.business_line = businessLineSelected?.id

  const response = await api.get(`${urlBase}`, { params })
  return {
    params,
    data: response.data
  }
})

export const getSuggestions = createAsyncThunk("coverages/getSuggestions",
  async (params = {}, { getState }) => {
    const state = getState()

    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines

    params.business_line = businessLineSelected?.id
    params.product = productSelected?.value

  const response = await api.get(`${urlBase}/suggestions`, {params})
  return {
    data: response.data
  }
})

export const getCoverageById = createAsyncThunk("coverages/getById", async (id) => {
  const response = await api.get(`${urlBase}/${id}`)
  return {
    data: response.data
  }
})

export const getModeTransportList = createAsyncThunk("modesTransport/list", async () => {
  const response = await api.get(`api/modes_transport/list`)
  return {
    data: response.data
  }
})

export const storeCoverage = createAsyncThunk("coverages/store", async (data, {dispatch, getState}) => {
  // get product select
  const state = getState()
  const { productSelected } = state.products
  data.producto = productSelected?.value

  const response = await api.post(`api/coverages`, data)
  if (response.data.status === 'success') {
    const url = `/catalogs/coverage`
    dispatch(showModalSuccess({message: response.data.message, url}))
  }
  return {
    data: response.data
  }
})

export const editCoverage = createAsyncThunk("coverages/edit", async ({id, data}, {dispatch, getState}) => {
  const state = getState()
  const {productSelected} = state.products
  data.producto = productSelected.value
  const response = await api.put(`${urlBase}/${id}`, data)
  if (response.data.status === 'success') {
    const url = `/catalogs/coverage`
    dispatch(showModalSuccess({message: response.data.message, url}))
  }
  return {
    data: response.data
  }
})

export const deleteCoverage = createAsyncThunk(
  "coverages/delete",
  async (id, { dispatch }) => {
    const response = await api.delete(`${urlBase}/${id}`)
    const url = `/catalogs/coverage`
    dispatch(getCoverages({page: 1}))
    dispatch(showModalSuccess({ message: response.data.message, url }))
    return {
      data: response.data
    }
  }
)

export const coverageSlice = createSlice({
  name: "coverage",
  initialState: {
    data: null,
    suggestions: [],
    defaultValues: null,
    concepts: [],
    params: null,
    modes_transport: [],
    total: 0
  },
  reducers: {
    setCoverages: (state, action) => {
      state.data = action.payload.data
    },
    clearCoverages: (state) => {
      state.data = null
      state.suggestions = []
      state.defaultValues = null
      state.concepts = []
      state.params = null
      state.modes_transport = []
      state.total = 0
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCoverages.fulfilled, (state, action) => {
      state.data = action.payload?.data
      state.total = action.payload?.data?.meta?.total
      state.params = action.payload?.params
    })
    .addCase(getModeTransportList.fulfilled, (state, action) => {
      state.modes_transport = action.payload.data
    })
    .addCase(storeCoverage.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    .addCase(getCoverageById.fulfilled, (state, action) => {
      const payload = action.payload.data.data
      state.defaultValues = {
        continuacion_viaje: payload.journey_continuation,
        concepto: payload.concept,
        deducible: payload.deductible_percentage,
        deducible_maximo: payload.max_deductible_percentage,
        deducible_minimo: payload.min_deductible_percentage,
        estado_mercancia: {
          label: payload.status === 'used' ? 'Usado' : 'Nuevo',
          value: payload.status
        },
        modo_transporte: payload.mode_transport,
        proveedor: {
          label: payload.provider.name,
          value: payload.provider.id
        },
        moneda: {
          label: payload.currency.cfdi_usage,
          value: payload.currency.id
        },
        // ensured_good: payload.ensured_good
        ensured_goods: {
          label: payload.ensured_good?.label,
          value: payload.ensured_good?.value
        }
      }
    })
    .addCase(getSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload.data
    })
    .addCase(editCoverage.fulfilled, (state) => {
      state.defaultValues = null
    })
  }
})

export const {setCoverages, clearCoverages} = coverageSlice.actions

export default coverageSlice.reducer
