import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getBusinessLines = createAsyncThunk("businessLines/get", async () => {
  const response = await api.get(`api/business_line`)
  return {
    data: response.data
  }
})

/**
 * @typedef {Object} BusinessLine
 * @property {number} id
 * @property {string} name
 */

/**
 * @typedef {Object} BusinessLinesState
 * @property {BusinessLine | null} businessLineSelected
 * @property {BusinessLine[]} businessLines
 */

/**
 * @type {BusinessLinesState}
 */
const initialState = {
  businessLineSelected: null,
  businessLines: []
}

const businessLineSlice = createSlice({
  name: "businessLines",
  initialState,
  reducers: {
    setBusinessLine: (state, action) => {
      state.businessLineSelected = action.payload
    },
    setBusinessLineById: (state, action) => {
      const searchValue = parseInt(action.payload)
      const businessLines = state.businessLines

      const businessLine = businessLines.filter((businessLine) => {
        return businessLine.id === searchValue
      })

      if (businessLine.length) {
        state.businessLineSelected = businessLine[0]
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessLines.fulfilled, (state, action) => {
      state.businessLines = action.payload.data

      if (!state.businessLineSelected && state.businessLines.length > 0) {
        state.businessLineSelected = state.businessLines[0]
      }
    })
  }
})

export const { setBusinessLine, setBusinessLineById } = businessLineSlice.actions
export default businessLineSlice.reducer
