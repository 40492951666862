// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"
import { showModalSuccess } from "../../../../redux/modalTypeSuccess"

const urlBase = "api/ensured-property"

export const getEnsuredGoods = createAsyncThunk(
  "ensuredGoods/getData",
  async (params, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    params.business_line = businessLineSelected?.id
    params.product = productSelected?.value
    const response = await api.get(`${urlBase}`, { params })
    return {
      params,
      data: response.data
    }
  }
)

export const getSuggestions = createAsyncThunk(
  "ensuredGoods/getSuggestions",
  async (params = {}, { getState }) => {
    const state = getState()

    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines

    params.business_line = businessLineSelected?.id
    params.product = productSelected?.value
    const response = await api.get(`${urlBase}/suggestions`, { params })
    return {
      data: response.data
    }
  }
)

export const getConcepts = createAsyncThunk(
  "ensuredGoods/getConcepts",
  async () => {
    const response = await api.get("api/concepts/ensured")
    return {
      data: response.data
    }
  }
)

export const getEnsuredGoodById = createAsyncThunk(
  "ensuredGoods/getDataById",
  async ({ id, params = {} }) => {
    const response = await api.get(`${urlBase}/${id}`, { params })
    return {
      data: response.data,
      params
    }
  }
)

export const getEnsuredGoodMaxMin = createAsyncThunk(
  "ensuredGoods/getDataMaxMin",
  async ({ id, params = {} }) => {
    const response = await api.get(`${urlBase}/${id}/max-min`, { params })
    return {
      data: response.data,
      params
    }
  }
)

export const editEnsuredGood = createAsyncThunk(
  "ensuredGoods/edit",
  async ({ id, data }, { dispatch }) => {
    const response = await api.put(`${urlBase}/${id}`, data)
    if (response.data.status === "success") {
      // show modal success
      dispatch(
        showModalSuccess(
          data.from === "providers"
            ? { message: response.data.message }
            : { message: response.data.message, url: "goBack" }
        )
      )
      await dispatch(getEnsuredGoods({ page: 1 }))
    }
    return {
      data: response.data
    }
  }
)

export const createEnsuredGood = createAsyncThunk(
  "ensuredGoods/createEnsuredGood",
  async (dataToCreate, { getState, dispatch }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    const response = await api.post(`${urlBase}`, {
      ...dataToCreate,
      producto: productSelected?.value,
      business_line: businessLineSelected?.id
    })
    if (response.data.status === "success") {
      // show modal success
      dispatch(
        showModalSuccess(
          dataToCreate.from === "providers"
            ? { message: response.data.message }
            : { message: response.data.message, url: "goBack" }
        )
      )
      await dispatch(getEnsuredGoods({ page: 1 }))
    }
    return {
      data: response.data
    }
  }
)

export const deleteEnsuredGood = createAsyncThunk(
  "ensuredGoods/delete",
  async (data, { dispatch }) => {
    const response = await api.delete(`${urlBase}/${data.id}`)
    if (response.data.status === "success") {
      if (data.section === "providers") {
        dispatch(showModalSuccess({ message: response.data.message }))
      } else if (data.section === "ensured_goods") {
        const url = "/catalogs/ensured-goods"
        dispatch(showModalSuccess({ message: response.data.message, url }))
        await dispatch(getEnsuredGoods({ page: 1 }))
      }
    }
    return {
      data: response.data
    }
  }
)

// TODO maybe move this function to another generic slice
export const getProviders = createAsyncThunk(
  "ensuredGoods/getProviders",
  async () => {
    const response = await api.get(`api/providers`)
    return {
      data: response.data
    }
  }
)

export const getProvidersOptions = createAsyncThunk(
  "ensuredGoods/getProvidersOptions",
  async (data, { getState }) => {
    const state = getState()
    const { productSelected } = state.products
    const { businessLineSelected } = state.businessLines
    const business_line = businessLineSelected?.id
    const response = await api.get(`api/providers`, {
      params: { ...data, format: "options", product: productSelected?.value, business_line }
    })
    return {
      data: response.data
    }
  }
)

export const ensuredGoodsSlice = createSlice({
  name: "ensuredGoods",
  initialState: {
    data: [],
    options: [],
    suggestions: [],
    concepts: [],
    currentEnsuredGood: {},
    providers: [],
    provider_options: [],
    params: {},
    meta: {},
    total: 0,
    message: "",
    status: {},
    placeholder: ""
  },
  reducers: {
    // clearEnsuredGoods:
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnsuredGoods.fulfilled, (state, action) => {
        if (action.payload?.params?.format === "options") {
          state.options = action.payload.data
        } else {
          state.data = []
          state.params = action.payload.params
          state.data = action.payload.data
          state.meta = action.payload.data.meta
          state.total = action.payload.data.meta.total
          // success
          state.message = ""
          state.status = {}
          state.currentEnsuredGood = {}
        }
      })
      .addCase(getEnsuredGoodById.fulfilled, (state, action) => {
        // console.debug("getEnsuredGoodById", action.payload)
        if (
          action.payload.params &&
          Object.keys(action.payload.params).length > 0
        ) {
          state.placeholder = action.payload.data
        } else {
          // use for devault values object
          const payload = action.payload.data.data
          state.currentEnsuredGood = {
            id: payload.id,
            concepto: payload.concept_option.value,
            provider: payload.provider_id,
            new_max_limit_mxn: payload.new_max_limit_mxn,
            new_min_limit_mxn: payload.new_min_limit_mxn,
            used_min_limit_mxn: payload.used_min_limit_mxn,
            used_max_limit_mxn: payload.used_max_limit_mxn,
            new_min_limit_usd: payload.new_min_limit_usd,
            new_max_limit_usd: payload.new_max_limit_usd,
            used_min_limit_usd: payload.used_min_limit_usd,
            used_max_limit_usd: payload.used_max_limit_usd
          }
          state.total = 1
        }
      })
      .addCase(getEnsuredGoodMaxMin.fulfilled, (state, action) => {
        // console.debug("getEnsuredGoodMaxMin", action.payload)
        if (
          action.payload.params &&
          Object.keys(action.payload.params).length > 0
        ) {
          state.placeholder = action.payload.data
        } else {
          // use for devault values object
          const payload = action.payload.data.data
          state.currentEnsuredGood = {
            id: payload.id,
            concepto: payload.concept_option.value,
            provider: payload.provider_id,
            new_max_limit_mxn: payload.new_max_limit_mxn,
            new_min_limit_mxn: payload.new_min_limit_mxn,
            used_min_limit_mxn: payload.used_min_limit_mxn,
            used_max_limit_mxn: payload.used_max_limit_mxn,
            new_min_limit_usd: payload.new_min_limit_usd,
            new_max_limit_usd: payload.new_max_limit_usd,
            used_min_limit_usd: payload.used_min_limit_usd,
            used_max_limit_usd: payload.used_max_limit_usd
          }
          state.total = 1
        }
      })
      .addCase(editEnsuredGood.fulfilled, (state, action) => {
        // console.debug("editEnsuredGood", action.payload)
        state.data = action.payload.data.data
        state.meta = null
        state.total = 1
        state.message = action.payload.data.message
        state.status = action.payload.data.status
        state.currentEnsuredGood = {}
        // console.debug("saving state", action.payload.data.status)
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.providers = action.payload.data
      })
      .addCase(getProvidersOptions.fulfilled, (state, action) => {
        state.provider_options = action.payload.data
      })
      .addCase(createEnsuredGood.fulfilled, (state, action) => {
        // console.debug("createEnsuredGood", action.payload)
        state.data = action.payload.data.data
        state.meta = null
        state.total = 0
        state.message = action.payload.data.message
        state.status = action.payload.data.status
      })
      .addCase(getConcepts.fulfilled, (state, action) => {
        state.concepts = action.payload.data
      })
      .addCase(getSuggestions.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
      })
  }
})

export default ensuredGoodsSlice.reducer
