import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/api/config";
import { RefreshTokenReturn } from "./auth.types";
import { AppDispatch, RootState } from "../store";
import { showLogoutModal } from "../modalSessionExpired";

export const revokeToken = createAsyncThunk(
  "autentication/logout",
  async () => {
    const response = await api.post(`api/revoke-token`);
    return {
      data: response.data
    };
  }
);
export const getMe = createAsyncThunk("autentication/me", async () => {
  const response = await api.get(`api/me`);
  return {
    data: response.data
  };
});

export const refreshToken = createAsyncThunk<
  RefreshTokenReturn,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("autentication/refresh", async (refreshToken, thunkAction) => {
  const { dispatch } = thunkAction;
  const response = await api.post(`auth/refresh`, { refreshToken });
  if (response.data.status === "error") {
    dispatch(showLogoutModal());
    throw new Error("Token Expired");
  }
  return {
    data: response.data
  };
});
