import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BankCardResponse,
  BankCardsResponse,
  BankCardsSuccessMessageResponse,
  SetupIntentResponse
} from "./bank-cards.types";
import { AppDispatch, RootState } from "../store";
import api from "@src/api/config";

export const getSetupIntent = createAsyncThunk<
  SetupIntentResponse,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("setup-intents/get", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as { businessLineSelected: { id: any } };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.get(`api/stripe/setup-intents?product=${product}&business_line=${business_line}`);

  return response.data;
});

export const getSetupIntentById = createAsyncThunk<
  SetupIntentResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("setup-intents/getById", async (id, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const businessLines = state.businessLines as { businessLineSelected: { id: any } };
  const business_line = businessLines.businessLineSelected.id;
  const product = products.productSelected.value;
  const response = await api.get(`api/stripe/setup-intents/${id}?product=${product}&business_line=${business_line}`);

  return response.data;
});

export const attachPaymentMethod = createAsyncThunk<
  BankCardResponse,
  string,
  { dispatch: AppDispatch; state: RootState }
>("bank-cards/attach", async (paymentMethodId, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const response = await api.post(`api/bank-cards/${product}`, {
    payment_method_id: paymentMethodId
  });

  return response.data.data;
});

export const getBankCards = createAsyncThunk<
  BankCardsResponse,
  undefined,
  { dispatch: AppDispatch; state: RootState }
>("bank-cards/get", async (_, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const response = await api.get(`api/bank-cards/${product}`);

  return response.data;
});

export const deleteBankCard = createAsyncThunk<
  BankCardsResponse,
  number,
  { dispatch: AppDispatch; state: RootState }
>("bank-cards/delete", async (id, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const response = await api.delete(`api/bank-cards/${product}/${id}`);

  return response.data;
});

export const setBankCardAsDefault = createAsyncThunk<
  BankCardsSuccessMessageResponse,
  number,
  { dispatch: AppDispatch; state: RootState }
>("bank-cards/default", async (id, thunkApi) => {
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  const response = await api.put(`api/bank-cards/${product}/${id}/default`);

  return {
    message: response.data.message
  };
});
