import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "@src/api/config";
import { AppDispatch, RootState } from "@src/redux/store";
import { setLoading } from "../../../redux/modalTypeDelete";
import SuccessToast from "../../../components/common/toast/SuccessToast";
import { toast } from "react-toastify";
import { ToastSuccess } from "@src/assets/images/icons";
import { DangerToast } from "@src/components/common/toast/ErrorToast";

export interface SendPaymentLinkToEmailParams {
  email: string;
  payment_link: string;
}

export interface SendPaymentLinkToEmailResponse {
  data: any;
  params: SendPaymentLinkToEmailParams;
}

export interface CreatePaymentLinkParams {
  amount: number;
  motive: string | null;
  invoicesSelected: any[];
  tax_data: string;
  description: string;
}

export interface CreatePaymentLinkResponse {
  data: any;
}

export const createPaymentLink = createAsyncThunk<
  CreatePaymentLinkResponse,
  CreatePaymentLinkParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("invoices/paymentLink", async (params, thunkApi) => {
  const { dispatch } = thunkApi;
  const state = thunkApi.getState();
  const products = state.products as { productSelected: { value: any } };
  const product = products.productSelected.value;
  try {
    const response = await api.post(`api/payment_link`, {
      ...params,
      product
    });
    dispatch(setLoading(false));
    return {
      data: response.data
    };
  } catch (err: any) {
    dispatch(setLoading(false));
    toast(
      <DangerToast
        message={
          err.response.data?.message ??
          "No fue posible generar el enlace de pago"
        }
      />,
      { hideProgressBar: true, className: "toast-danger", autoClose: 5000 }
    );
    throw new Error(
      err.response.data?.message ?? "No fue posible generar el enlace de pago"
    );
  }
});

export const sendPaymentLinkToEmail = createAsyncThunk<
  SendPaymentLinkToEmailResponse,
  SendPaymentLinkToEmailParams,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>("invoices/sendPaymentLinkToEmail", async (params, { dispatch }) => {
  try {
    const response = await api.post(`api/payment_link/send`, { ...params });

    dispatch(setLoading(false));
    if (response.data.status === "success") {
      toast(
        <SuccessToast message={response.data.message} submessage={null} />,
        {
          icon: <ToastSuccess />,
          hideProgressBar: true,
          className: "toast-success",
          autoClose: 5000
        }
      );
    }
    return {
      data: response.data,
      params
    };
  } catch (err: any) {
    dispatch(setLoading(false));
    toast(
      <DangerToast
        message={
          err.response.data?.message ?? "No fue posible enviar enlace de pago"
        }
      />,
      { hideProgressBar: true, className: "toast-danger", autoClose: 5000 }
    );
    throw new Error(
      err.response.data?.message ?? "No fue posible enviar el enlace de pago"
    );
  }
});
