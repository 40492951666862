import { createSlice } from "@reduxjs/toolkit";
import { PaymentsReferencesState } from "./payment-references.types";
import {
  approveManualPayment,
  createBankTransfer,
  createBankTransferStripe,
  createChargeWithCard,
  createMxnTransfer,
  createStoreCharge,
  createUsdTransfer,
  getPayment,
  getPreviewRep,
  getPreviewRepByOpenpayId,
  getVouchersUrl,
  stripeCardPayment,
  uploadVoucher
} from "./async-thunks";

const initialState: PaymentsReferencesState = {
  successResponse: false,
  stateLoading: false,
  certificateSelected: [],
  paymentResponse: [],
  transferBankAccount: null,
  transferUsd: null,
  transferMxn: null,
  storeCharge: null,
  invoicesSelected: [],
  preview_url: "",
  payment: null,
  paymentSelected: null,
  vouchers: [],
  cardPayment: null,
  cardPaymentError: null
};

export const paymentReferencesSlice = createSlice({
  name: "paymentReferences",
  initialState,
  reducers: {
    clearPaymentReferences: (state) => {
      state.successResponse = false;
      state.stateLoading = false;
      state.certificateSelected = [];
      state.paymentResponse = [];
      state.transferBankAccount = false;
      state.transferUsd = false;
      state.transferMxn = false;
      state.invoicesSelected = [];
      state.preview_url = "";
      state.payment = null;
      state.paymentSelected = null;
      state.cardPayment = null;
      state.cardPaymentError = null;
    },
    clearPayments: (state) => {
      state.payment = null;
      state.successResponse = false;
      state.stateLoading = false;
      state.transferBankAccount = false;
      state.transferUsd = false;
      state.transferMxn = false;
      state.storeCharge = null;
      state.cardPayment = null;
      state.cardPaymentError = null;
    },
    clearPreviewUrl: (state) => {
      state.preview_url = "";
    },
    addItems: (state, action) => {
      localStorage.setItem(
        "selectedRows",
        JSON.stringify(action.payload.selectedRows)
      );
      state.certificateSelected = action.payload.selectedRows;
    },
    removeItem: (state, action) => {
      const tmp = state.certificateSelected;
      if (tmp.length > 1) {
        for (let i = 0; i < state.certificateSelected.length; i++) {
          if (i === action.payload.index) {
            tmp.splice(i, 1);
          }
        }
        state.certificateSelected = tmp;
      } else {
        state.certificateSelected = [];
      }
    },
    startLoading: (state) => {
      state.stateLoading = true;
    },
    stopLoading: (state) => {
      state.stateLoading = false;
    },
    addInvoiceItem: (state, action) => {
      localStorage.setItem(
        "selectedInvoices",
        JSON.stringify(action.payload.selectedInvoices)
      );
      state.invoicesSelected = action.payload.selectedInvoices;
    },
    removeInvoiceItem: (state, action) => {
      const tmp = state.invoicesSelected;
      if (tmp.length > 1) {
        for (let i = 0; i < state.invoicesSelected.length; i++) {
          if (i === action.payload.index) {
            tmp.splice(i, 1);
          }
        }
        state.invoicesSelected = tmp;
      } else {
        state.invoicesSelected = [];
      }
    },
    setPaymentSelected: (state, action) => {
      state.paymentSelected = action.payload.payment;
      localStorage.setItem(
        "show_payment",
        JSON.stringify(action.payload.payment)
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createChargeWithCard.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.paymentResponse = action.payload.data;
        state.successResponse = true;
      }
      state.stateLoading = false;
    });
    builder.addCase(createBankTransfer.fulfilled, (state, action) => {
      state.transferBankAccount = {
        ...action.payload.data.bank_transfer,
        require_payment: true
      };
      state.payment = action.payload.data.payment;
    });
    builder.addCase(createBankTransferStripe.fulfilled, (state, action) => {
      state.transferBankAccount = {
        ...action.payload.data.bank_transfer,
        require_payment: action.payload.data.require_payment
      };
      state.payment = action.payload.data.payment;
    });
    builder.addCase(createStoreCharge.fulfilled, (state, action) => {
      state.storeCharge = action.payload.data.store_charge;
      state.payment = action.payload.data.payment;
    });
    builder.addCase(createUsdTransfer.fulfilled, (state, action) => {
      state.transferUsd = true;
      state.payment = action.payload.data.data;
    });
    builder.addCase(createMxnTransfer.fulfilled, (state, action) => {
      state.transferMxn = true;
      state.payment = action.payload.data.data;
    });
    builder.addCase(getPreviewRep.fulfilled, (state, action) => {
      state.preview_url = action.payload.data;
    });
    builder.addCase(getPreviewRepByOpenpayId.fulfilled, (state, action) => {
      state.preview_url = action.payload.data.data.url;
      state.paymentResponse = action.payload.data.data.payment;
    });
    builder.addCase(uploadVoucher.fulfilled, (state, action) => {
      state.paymentSelected = action.payload.data.data;
    });
    builder.addCase(uploadVoucher.rejected, (state, action) => {});
    builder.addCase(getVouchersUrl.fulfilled, (state, action) => {
      state.vouchers = action.payload.data;
    });
    builder.addCase(getPayment.fulfilled, (state, action) => {
      localStorage.setItem(
        "show_payment",
        JSON.stringify(action.payload.data.data)
      );
      state.paymentSelected = action.payload.data.data;
    });
    builder.addCase(approveManualPayment.fulfilled, (state, action) => {
      if (action.payload?.data?.data) {
        state.paymentSelected = action.payload.data.data;
      }
    });
    builder.addCase(stripeCardPayment.pending, (state) => {
      state.stateLoading = true;
    });
    builder.addCase(stripeCardPayment.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.cardPayment = action.payload.data;
        state.successResponse = true;
      }
      state.stateLoading = false;
    });
    builder.addCase(stripeCardPayment.rejected, (state) => {
      state.stateLoading = false;
      state.cardPaymentError = "Error al realizar el pago";
    });
  }
});

export const {
  removeItem,
  startLoading,
  stopLoading,
  addItems,
  removeInvoiceItem,
  addInvoiceItem,
  clearPaymentReferences,
  clearPayments,
  setPaymentSelected,
  clearPreviewUrl
} = paymentReferencesSlice.actions;

export const paymentReferencesReducer = paymentReferencesSlice.reducer;
