// ** Redux Imports
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
import reactotron from "../ReactotronConfig";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  },
  enhancers: [reactotron.createEnhancer()]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store };
