// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import api from "@src/api/config"

export const getCurrentBalance = createAsyncThunk(
  "currentBalance/get",
  async (params, { getState }) => {
    try {
      const state = getState()
      const businessLines = state.businessLines?.businessLineSelected?.id
      params.business_line = businessLines
      const response = await api.get(`api/balance/current`, { params })
      return {
        data: response?.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)
export const getDueBalance = createAsyncThunk(
  "dueBalance/get",
  async (params, { getState }) => {
    try {
      const state = getState()
      const businessLines = state.businessLines?.businessLineSelected?.id
      params.business_line = businessLines
      const response = await api.get(`api/balance/due`, { params })
      return {
        data: response?.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)

export const getBalanceSuggestions = createAsyncThunk(
  "currentBalance/getSuggestions",
  async (params, { getState }) => {
    try {
      const state = getState()
      const businessLines = state.businessLines?.businessLineSelected?.id
      params.business_line = businessLines
      const response = await api.get(`api/balance/suggestions`, { params })
      return {
        data: response?.data,
        params
      }
    } catch (err) {
      // console.error(err)
    }
  }
)

export const balancesSlice = createSlice({
  name: "balances",
  initialState: {
    // current balance
    current_balance: {}, // all row data
    current_balance_suggestions: [],
    current_balance_suggestions_status: 'idle',
    current_balance_status: "idle",
    pagination: null,
    // filters
    params: {
      page: 1, // current page
      type: "folio", // current filter type
      orderBy: 'folio',
      sort: 'desc',
      folio: null,
      tab: 'current_balance',
      // filters section
      only_me: false,
      currency: null,
      created_at: null,
      uncollectible_at: null,
      paid_at: null,
      payment_status: null,
      remaining_days: null,
      payment_moment: null
    },
    filters: 0,
    // search by
    search_key: 'folio',
    search_label: 'Folio',
    search_value: '',
    // config
    rows_per_page: 10,
    total_rows: 1
  },
  reducers: {
    clearFilters: (state) => {
      state.params = {
        page: 1, // current page
        type: "folio", // current filter type
        orderBy: 'folio',
        sort: 'desc',
        folio: null,
        // tab: 'current_balance',
        tab: state.params.tab,
        // filters section
        only_me: false,
        currency: null,
        created_at: null,
        uncollectible_at: null,
        paid_at: null,
        payment_status: null,
        remaining_days: null,
        payment_date: null,
        payment_moment: null
      }
    },
    clearSearch: (state) => {
      state.search_value = ""
      state.params = {
        ...state.params,
        page: 1,
        created_at: null,
        paid_at: null
      }
      /* eslint-disable space-unary-ops */
      state.filters = 
        + (state.params.currency ? 1 : 0) 
        + (state.params.created_at ? 1 : 0) 
        + (state.params.uncollectible_at ? 1 : 0) 
        + (state.params.paid_at ? 1 : 0)
        + (state.params.remaining_days ? 1 : 0)
        + (state.params.payment_status?.length > 0 ? 1 : 0)
        + (state.params.payment_ref ? 1 : 0)
        + (state.params.payment_date ? 1 : 0)
        + (state.params.payment_moment ? 1 : 0)
    },
    setOnlyMe: (state, action) => {
      state.params = { ...state.params, only_me: action.payload.only_me }
    },
    setCurrentPage: (state, action) => {
      state.params = { ...state.params, page: action.payload.page }
    },
    setCurrentSort: (state, action) => {
      state.params = {
        ...state.params,
        orderBy: action.payload.orderBy,
        sort: action.payload.sort
      }
    },
    setCurrentTab: (state, action) => {
      /* eslint-disable no-unused-vars */
      const { folio, client, seller, tax_data, ...auxParams } = state.params
      state.params = {
        ...auxParams,
        tab: action.payload.tab
      }
      state.search_value = ""
    },
    setSearchBy: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
    setFilterSelected: (state, action) => {
      state.search_key = action.payload.key
      state.search_label = action.payload.label
      state.search_value = action.payload.value
    },
    setGlobalFilterSelected: (state, action) => {
      // parse
      const payment_status = []
      if (action.payload?.payment_status) {
        action.payload?.payment_status.forEach(element => {
          payment_status.push(element.value)
        })
      }

      let remaining_days = null
      if (action.payload?.remaining_days) {
        remaining_days = action.payload?.remaining_days?.value
      }

      state.params = {
        ...state.params,
        page: 1, // reset pagination
        currency: action.payload.currency,
        only_me: action.payload.only_me,
        created_at: action.payload.created_at,
        uncollectible_at: action.payload.uncollectible_at,
        paid_at: action.payload.paid_at,
        remaining_days,
        payment_status,
        payment_ref: action.payload.payment_ref?.value,
        payment_date: action.payload.payment_date,
        payment_moment: action.payload.payment_moment
      }
      /* eslint-disable space-unary-ops */
      state.filters = 
        + (action.payload.currency ? 1 : 0) 
        + (action.payload.created_at ? 1 : 0) 
        + (action.payload.uncollectible_at ? 1 : 0) 
        + (action.payload.paid_at ? 1 : 0)
        + (remaining_days ? 1 : 0)
        + (payment_status.length > 0 ? 1 : 0)
        + (action.payload.payment_ref ? 1 : 0)
        + (action.payload.payment_date ? 1 : 0)
        + (action.payload.payment_moment ? 1 : 0)
    }
  },
  extraReducers: (builder) => {
    // Current balance
    builder.addCase(getCurrentBalance.pending, (state) => {
      state.current_balance_status = "loading"
    })
    builder.addCase(getCurrentBalance.fulfilled, (state, action) => {
      state.current_balance_status = "succeeded"
      state.current_balance = action?.payload?.data?.data
      state.pagination = action?.payload?.data?.meta
      state.total_rows = action?.payload?.data?.meta?.total
    })
    builder.addCase(getCurrentBalance.rejected, (state) => {
      state.current_balance_status = "failed"
    })
    // Suggestions current balance
    builder.addCase(getBalanceSuggestions.pending, (state) => {
      state.current_balance_suggestions_status = 'loading'
    })
    builder.addCase(getBalanceSuggestions.fulfilled, (state, action) => {
      state.current_balance_suggestions_status = 'succeeded'
      state.current_balance_suggestions = action?.payload?.data
    })
    builder.addCase(getBalanceSuggestions.rejected, (state) => {
      state.current_balance_suggestions_status = 'failed'
    })
  }
})

export const {
  setCurrentPage,
  setCurrentSort,
  setSearchBy,
  setFilterSelected,
  setCurrentTab,
  setOnlyMe,
  setGlobalFilterSelected,
  clearFilters,
  clearSearch
} = balancesSlice.actions

export default balancesSlice.reducer
