//PRODUCTS ID NUMBER
export const const_products = {
  LOADS: 1,
  ELECTRONIC_EQUIPMENT: 2,
  RC_CONTRACTORS: 3,
  PERSONAL_ACCIDENTS: 4,
  ORBE_360: 5,
  ERN: 6
}

export const const_type_notifications = {
  CERTIFICADOS: 1,
  FACTURAS: 2,
  RECIBO_DE_PAGOS: 3,
  PAGOS: 4,
  CONCILIACIONES: 5,
  OTROS: 6
}

export const const_subtype_notifications = {
  EMISION_DE_CERTIFICADOS: 1,
  ERROR_DE_CERTIFICADOS: 2,
  EMISION_DE_FACTURAS: 3,
  EMISION_DE_RECIBO_DE_PAGO: 4,
  EMISION_DE_PAGOS: 5,
  ERROR_EN_LA_FACTURA: 6,
  SOLICITUD_DE_CANCELACION: 7,
  FACTURA_CANCELADA: 8,
  CANCELACION_DE_CERTIFICADOS: 9,
  PAGOS_REALIZADOS: 10,
  PAGOS_CONCILIADOS: 11,
  PAGOS_RECHAZADOS: 12,
  PROBLEMAS_EN_PAGOS: 13,
  PAGOS_CANCELADOS: 14,
  AVISO_DE_PAGO_PROXIMO: 15,
  REP: 16,
  ACTUALICACION_DE_DATOS: 17,
  NUEVA_RAZON_SOCIAL: 18,
  OTROS: 19
}

export const notificationTypes = {
  1: {
    folioKey: "folio",
    statusKey: "certificate_status",
    statusMapping: {
      issued: "Emitido",
      commissioned: "Comisionado",
      outstanding: "Pago Pendiente",
      overdue: "Atrasado",
      uncollectible: "Incobrable",
      with_invoice: "Con factura",
      with_reference: "Con referencia",
      cancelled: "Cancelado"
    },
    status: ["Emitido", "Comisionado", "Pendiente", "Atrasado", "Incobrable", "Con factura", "Con referencia", "Cancelado"],
    buttonLabel: "Ver certificado",
    route: "certs"
  },
  2: {
    folioKey: "folio_full",
    statusKey: "status",
    statusMapping: {
      issued: "Emitido",
      commissioned: "Comisionado",
      internal_request: "Solicitud interna",
      cancelled: "Cancelado"
    },
    status: ["Emitido", "Comisionado", "Solicitud Interna", "Cancelado"],
    buttonLabel: "Ver factura",
    route: "invoices"
  },
  3: {
    statusKey: "status",
    statusMapping: {
      without_voucher: "Sin voucher",
      conciliar: "Por conciliar",
      voucher: "Pendiente",
      expired: "Expirado",
      completed: "Completado",
      charge_pending: "Cargo pendiente",
      in_progress: "En proceso",
      canceled: "Cancelado"
    },
    status: ["Sin Voucher", "Por conciliar", "Pendiente", "Expirado", "Completado", "Cargo pendiente", "En proceso", "Cancelado"],
    buttonLabel: "Ver pago",
    route: "payments"
  },
  4: {
    folioKey: "folio_number",
    statusKey: "status",
    statusMapping: {
      without_voucher: "Sin voucher",
      conciliar: "Por conciliar",
      voucher: "Pendiente",
      expired: "Expirado",
      completed: "Completado",
      charge_pending: "Cargo pendiente",
      in_progress: "En Proceso",
      canceled: "Cancelado"
    },
    status: ["Sin voucher", "Por conciliar", "Pendiente", "Expirado", "Completado", "Cargo pendiente", "En proceso", "Cancelado"],
    buttonLabel: "Ver pago",
    route: "payments"
  },
  5: {
    buttonLabel: "Ver conciliacion",
    route: "payments"
  }
}

export const notificationMessages = {
  1: "Nuevo certificado emitido",
  2: "Error en certificado",
  3: "Nueva factura emitida",
  4: "Nuevo recibo de pago emitido",
  5: "Nuevo pago emitido",
  6: "Error en factura emitida",
  7: "Cancelación de factura solicitada",
  8: "Factura cancelada",
  9: "Cancelación de certificados",
  10: "Nuevo pago realizado",
  11: "Nuevo pago conciliado exitosamente",
  12: "Pago rechazado",
  13: "Problemas en pagos",
  14: "Pago cancelado",
  15: "Pago próximo a pagar",
  16: "Nuevo REP disponible",
  17: "Datos actualizados",
  18: "Nueva Razón Social"
}

export const const_products_id_type = {
  LOADS: [1, 6],
  ELECTRONIC_EQUIPMENT: [2],
  RC_CONTRACTORS: [3],
  PERSONAL_ACCIDENTS: [4],
  ORBE_360: [5]
}

export const const_products_type = {
  LOADS: "loads",
  ELECTRONIC_EQUIPMENT: "ee",
  RC_CONTRACTORS: "rc",
  PERSONAL_ACCIDENTS: "ap",
  ORBE_360: "o360"
}

export const userRoles = {
  ADMIN: "admin",
  SELLER: "seller",
  ACCOUNTANT: "accountant",
  CLIENT: "client"
}