import axios from "axios"
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { store } from '@store/store'
import { setResponseMesage } from '@store/response'
import { cleanErrors, setLoading, setNotLoading } from "../redux/response"
import ErrorToast from "../components/common/toast/ErrorToast"
// import { useDispatch } from "react-redux"

const config = useJwt.jwtConfig
export const front_base_url =  process.env.REACT_APP_FRONT_URL ? process.env.REACT_APP_FRONT_URL : "http://localhost"
const api = axios.create({
  baseURL: front_base_url
  // mode: 'no-cors'
})

api
  .interceptors
  .request
  .use(function (config) {
    store.dispatch(cleanErrors())
    store.dispatch(setLoading({resource: config.url}))
    // Append token foreach request
    if (localStorage.getItem("token")) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

api.interceptors.response.use(
      response => {
        store.dispatch(setNotLoading())
        return response
      },
      error => {
        store.dispatch(setNotLoading())
        // console.debug('error request', error)
        // ** const { config, response: { status } } = error
        const { response } = error
        if (response && response.status === 500) {
          if (process.env.NODE_ENV === 'development') {
            ErrorToast({message: response?.data?.message ? response.data.message : "Algo salió mal"})
          }
          store.dispatch(setResponseMesage({message: "Algo salió mal", errors: {general: "Error en el servidor"}, status: "error"}))
        }

        // validation error
        if (response && response.status === 422) {
          for (const key in response.data.errors) {
            const messages = {}
            messages[key] = response.data.errors[key]
            if (process.env.NODE_ENV === 'development') {
              ErrorToast({message: response?.data?.message ? response.data.message : "Algo salió mal"})
            }
            // console.debug("error messages: ", messages)
            store.dispatch(setResponseMesage({message: response.data.message, errors: messages, status: "error"}))
          }
        }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          // ** Remove user, accessToken & refreshToken from localStorage
          localStorage.removeItem('userData')
          localStorage.removeItem(config.storageTokenKeyName)
          localStorage.removeItem(config.storageRefreshTokenKeyName)
          // window.location.href = "/login"
        }
        return Promise.reject(error)
      }
    )


    export default api
